<template>
    <div>
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-space-between">
                    <div style="padding: 18px 12px">
                        <div class="text-2xl"><i :class="$route.params.icon"></i> {{ $route.params.title }}</div>
                    </div>
                    <v-breadcrumbs
                        class="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        :items="$route.meta.breadcrumb"
                        divider=">"
                        large
                    />
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-stepper non-linear v-model="stepwizard">
                    <v-stepper-header>
                        <v-stepper-step step="1" >
                            ຂໍ້ມູນຕົ້ນທາງ - ປາຍທາງ
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2" >
                            ຂໍ້ມູນພັດສະດຸ
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-form v-model="customerValid" ref="formcustomerdata" class="pt-2" lazy-validation>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <div class="border rounded mt-4 p-2">
                                            <div>
                                                <v-alert
                                                    color="blue-grey lighten-4"
                                                    shaped
                                                    dense
                                                    class="text-center"
                                                >
                                                    <strong>ຂໍ້ມູນຕົ້ນທາງ</strong>
                                                </v-alert>
                                            </div>
                                            <div class="pt-3">
                                                <v-alert
                                                    color="green darken-4"
                                                    dense
                                                    class="text-center white--text"
                                                >
                                                    <strong>ສາຂາຕົ້ນທາງ: {{ $store.state.auth.branchname }}</strong>
                                                </v-alert>
                                            </div>
                                            <div>
                                                <v-autocomplete
                                                    label="ຂໍ້ມູນຜູ້ຝາກ"
                                                    :items="listCustomer"
                                                    item-text="text"
                                                    item-value="id"
                                                    outlined  
                                                    dense
                                                    clearable
                                                    v-model="formCustomerData.form_customer" 
                                                    @change="getListSelectCustomerFormData()"
                                                />
                                            </div>
                                            <div>
                                                <v-text-field 
                                                    type="text" 
                                                    label="ຊື່ຜູ້ຝາກ" 
                                                    outlined  
                                                    dense
                                                    v-model="formCustomerData.form_customer_name" 
                                                    :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']" 
                                                    required 
                                                    append-icon="mdi-card-account-mail-outline"  
                                                />
                                            </div>
                                            <div>
                                                <v-text-field 
                                                    type="text" 
                                                    label="ເບີໂທລະສັບຜູ້ຝາກ" 
                                                    outlined  
                                                    dense
                                                    v-model="formCustomerData.form_customer_tel" 
                                                    :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']" 
                                                    required 
                                                    append-icon="mdi-card-account-phone"  
                                                />
                                            </div>
                                            <div>
                                                <v-textarea
                                                    label="ລາຍລະອຽດຜູ້ຝາກ" 
                                                    outlined  
                                                    v-model="formCustomerData.form_customer_address" 
                                                    :rows="3"
                                                />
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <div class="border rounded mt-4 p-2">
                                            <div>
                                                <v-alert
                                                    color="blue-grey lighten-4"
                                                    shaped
                                                    dense
                                                    class="text-center"
                                                >
                                                    <strong>ຂໍ້ມູນປາຍທາງ</strong>
                                                </v-alert>
                                            </div>
                                            <div>
                                                <v-autocomplete
                                                    label="ສາຂາປາຍທາງ"
                                                    :items="listBranch"
                                                    item-text="text"
                                                    item-value="id"
                                                    v-model="formCustomerData.to_branch" 
                                                    :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']"  
                                                    required
                                                    outlined  
                                                    dense
                                                />
                                            </div>
                                            <div>
                                                <v-autocomplete
                                                    label="ຂໍ້ມູນຜູ້ຮັບ"
                                                    :items="listCustomer"
                                                    item-text="text"
                                                    item-value="id"
                                                    outlined  
                                                    dense
                                                    clearable
                                                    v-model="formCustomerData.to_customer" 
                                                    @change="getListSelectCustomerToData()"
                                                />
                                            </div>
                                            <div>
                                                <v-text-field 
                                                    type="text" 
                                                    label="ຊື່ຜູ້ຮັບ" 
                                                    outlined  
                                                    dense
                                                    v-model="formCustomerData.to_customer_name" 
                                                    :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']" 
                                                    required 
                                                    append-icon="mdi-card-account-mail-outline"  
                                                />
                                            </div>
                                            <div>
                                                <v-text-field 
                                                    type="text" 
                                                    label="ເບີໂທລະສັບຜູ້ຮັບ" 
                                                    outlined  
                                                    dense
                                                    v-model="formCustomerData.to_customer_tel" 
                                                    :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']" 
                                                    required 
                                                    append-icon="mdi-card-account-phone"  
                                                />
                                            </div>
                                            <div>
                                                <v-textarea
                                                    label="ລາຍລະອຽດຜູ້ຮັບ" 
                                                    outlined  
                                                    v-model="formCustomerData.to_customer_addredd" 
                                                    :rows="3"
                                                />
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-form>
                            <div class="text-right d-flex justify-between">
                                <v-btn class="mx-1" outlined color="primary" @click="goNextStep()">ຕໍ່ໄປ <v-icon>mdi-chevron-right</v-icon></v-btn>
                                <v-btn class="mx-1" color="error" @click="$router.push({ name: 'import'})"><v-icon>mdi-close-circle-outline</v-icon> ກັບຄືນ</v-btn>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <v-row>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <div class="mt-3">
                                        <v-alert
                                            color="blue-grey lighten-4"
                                            shaped
                                            dense
                                            class="text-center"
                                        >
                                            <strong>ຂໍ້ມູນພັດສະດຸ</strong>
                                        </v-alert>
                                    </div>
                                    <v-form v-model="packageValid" ref="formpackagedata" class="pt-2" lazy-validation @submit.prevent="addMorePackage()">
                                        <v-row>
                                            <v-col cols="12" sm="12" md="7" lg="7" xl="7">
                                                <v-text-field 
                                                    type="text" 
                                                    label="ຊື່ພັດສະດຸ" 
                                                    outlined  
                                                    dense
                                                    v-model="formPackageData.package_name" 
                                                    :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']"
                                                    required 
                                                    append-icon="mdi-barcode-scan"  
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="d-flex">
                                                <v-numeric 
                                                    text 
                                                    outlined 
                                                    dense 
                                                    v-model="formPackageData.package_price" 
                                                    :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']"
                                                    :precision="0" 
                                                    label="ລາຄາ" 
                                                    :min="0" 
                                                    required 
                                                    openKey="F1"
                                                />
                                                <v-btn
                                                type="submit"
                                                color="success"
                                                text
                                                large
                                                
                                                ><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                    <v-data-table
                                        :headers="packageField"
                                        :items="listPackage"
                                        :search="search"
                                        :items-per-page="5"
                                        class="elevation-1"
                                        :loading="$store.state.auth.loading2"
                                        loading-text="ກຳລັງໂຫລດ...ກະລຸນາລໍຖ້າ"
                                        item-key="br_id"
                                        v-model="selected"
                                        fixed-header
                                        height="236px"
                                        dense
                                        :footer-props="{
                                            'items-per-page-options': [5, 10, 20, 100, -1],
                                            showFirstLastPage: true,
                                            'items-per-page-text': 'ຈຳນວນທີ່ສະແດງ:'
                                    }"
                                    >
                                    <template v-slot:item.option="{ item, index }">
                                        <div class="whitespace-nowrap">
                                            <v-btn
                                                class="mr-1"
                                                color="primary"
                                                fab
                                                small
                                                dark
                                                text 
                                                @click="getEditPackage(item)"
                                            >
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn
                                                class="mr-1"
                                                color="error"
                                                fab
                                                small
                                                dark
                                                text 
                                                @click="deleteData(index)"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>

                                        </div>
                                    </template>
                                    <template v-slot:item.package_amount="{ item }">
                                        {{ $helper.formatNumber(item.package_amount, 0) }}
                                    </template>
                                    <template v-slot:item.package_price="{ item }">
                                        <div class="whitespace-nowrap">
                                        {{ $helper.formatNumber(item.package_price, 0) }}
                                        </div>
                                    </template>
                                    
                                    </v-data-table>
                                    <div>
                                        <v-alert
                                            color="black white--text"
                                            dense
                                            class="text-right whitespace-nowrap d-none d-sm-block d-md-block d-lg-block d-xl-block"
                                        >
                                            ລວມເປັນເງີນທັງໝົດ {{ $helper.formatNumber(sumtotal,0) }} ກີບ
                                        </v-alert>
                                        <v-alert
                                            color="black white--text"
                                            dense
                                            class="text-right whitespace-nowrap d-block d-sm-none d-md-none d-lg-none d-xl-none"
                                        >
                                            {{ $helper.formatNumber(sumtotal,0) }} ກີບ
                                        </v-alert>
                                    </div>
                                </v-col>
                            </v-row>
                            <div class="text-right d-flex justify-between">
                                <v-btn class="mx-1" outlined color="primary" @click="stepwizard = 1"><v-icon>mdi-chevron-left</v-icon> ກັບຄືນ</v-btn>
                                <div class="d-flex">
                                    <v-btn class="mx-1" color="success" @click="saveData()"><v-icon>mdi-content-save-outline</v-icon>ບັນທຶກ</v-btn>
                                    <v-btn class="mx-1" color="error" @click="$router.push({ name: 'import'})"><v-icon>mdi-close-circle-outline</v-icon> ກັບຄືນ</v-btn>
                                </div>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
        <v-dialog
            v-model="formDialog"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-card-title class="amber lighten-1 black--text">
                    <span class="text-3xl"><v-icon>mdi-pencil</v-icon> ແກ້ໄຂລາຍການພັດສະດຸ</span>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="editPackageValid" ref="formEditPackagedata" class="pt-2" lazy-validation>
                        <div class="border rounded mt-4 p-4">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field 
                                        type="text" 
                                        label="ຊື່ພັດສະດຸ" 
                                        outlined  
                                        dense
                                        v-model="formEditPackageData.package_name" 
                                        :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']"
                                        required 
                                        append-icon="mdi-package-variant-closed"  
                                    />
                                </v-col>
                                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                    <v-numeric 
                                        text 
                                        outlined 
                                        dense 
                                        v-model="formEditPackageData.package_amount" 
                                        :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']"
                                        :precision="0" 
                                        label="ຈຳນວນ" 
                                        :min="0" 
                                        required 
                                        openKey="F1"
                                    />
                                </v-col>
                                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                    <v-numeric 
                                        text 
                                        outlined 
                                        dense 
                                        v-model="formEditPackageData.package_price" 
                                        :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']"
                                        :precision="0" 
                                        label="ລາຄາ" 
                                        :min="0" 
                                        required 
                                        openKey="F1"
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="ລາຍລະອຽດພັດສະດຸ" 
                                        outlined  
                                        v-model="formEditPackageData.package_description" 
                                        :rows="5"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>   
                </v-card-text>
                <v-divider></v-divider>
                <v-container>
                    <v-row justify="end" class="p-1">
                        <v-btn class="mx-1" color="success" @click="saveEdit()"><i class="mdi mdi-content-save-outline"></i> ບັນທຶກ</v-btn>
                        <v-btn class="mx-1" color="error" @click="formDialog = false"><i class="mdi mdi-close-circle-outline"></i> ກັບຄືນ</v-btn>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        watch: {
            stepwizard(data){
                if(data == 1){
                    this.isprocess = 50;
                }else if(data == 2){
                    this.isprocess = 100;
                }
            }
        },
        data() {
            return {
                stepwizard: 1,
                req_id: '',
                action: '',
                customerValid: false,
                packageValid: false,
                formManage: {
                    br_name_la: '',
                    br_name_en: '',
                    br_tel: '',
                    br_address: '',
                },
                packageField: [
                    {
                        text: 'ຕົວເລືອກ',
                        align: 'center',
                        sortable: false,
                        value: 'option',
                        class: 'black--text title amber lighten-1 black--text col-2 whitespace-nowrap'
                    },
                    {
                        text: '#',
                        align: 'center',
                        sortable: false,
                        value: 'rno',
                        class: 'black--text title whitespace-nowrap amber lighten-1 black--text'
                    },
                    {
                        text: 'ຊື່ພັດສະດຸ',
                        align: 'start',
                        sortable: true,
                        value: 'package_name',
                        class: 'black--text title whitespace-nowrap amber lighten-1 black--text col-3'
                    },
                    {
                        text: 'ຈຳນວນ',
                        align: 'center',
                        sortable: true,
                        value: 'package_amount',
                        class: 'black--text title whitespace-nowrap amber lighten-1 black--text col-1'
                    },
                    {
                        text: 'ລາຄາ',
                        align: 'end',
                        sortable: true,
                        value: 'package_price',
                        class: 'black--text title whitespace-nowrap amber lighten-1 black--text col-3'
                    },
                    {
                        text: 'ລາຍລະອຽດພັດສະດຸ',
                        align: 'start',
                        sortable: true,
                        value: 'package_description',
                        class: 'black--text title whitespace-nowrap amber lighten-1 black--text col-3'
                    },
                ],
                listBranch: [],
                listCustomer: [],
                selected: [],
                search: "",
                listdata: [],
                formCustomerData: {
                    form_customer: '',
                    form_customer_name: '',
                    form_customer_tel: '',
                    form_customer_address: '',
                    to_branch: '',
                    to_customer: '',
                    to_customer_name: '',
                    to_customer_tel: '',
                    to_customer_addredd: '',
                },
                formPackageData: {
                    package_name: '',
                    package_price: 0,
                },
                listPackage: [],
                sumtotal: 0,
                // modal data
                formDialog: false,
                packageindex: 0,
                editPackageValid: false,
                formEditPackageData: {
                    package_name: '',
                    package_amount: 0,
                    package_price: 0,
                    package_description: '',
                }
            }
        },
        async created(){
            await this.getListSelectboxBranch();
            await this.getListSelectboxCustomer();
            this.customerValid = await false;
            this.packageValid = await false;
            if(this.customerValid){
                await this.$refs.formcustomerdata.resetValidation();
            }
            if(this.packageValid){
                await this.$refs.formpackagedata.resetValidation();
            }
        },
        methods: {
            async getListSelectboxBranch(){
                const formdata = await {
                    action: "listbranch",
                    id: "",
                }
                this.listBranch = await [];
                const result = await this.$store.dispatch("params/getListSelectBox",formdata);
                if(result.success){
                    this.listBranch = await result.data;
                }
            },
            async getListSelectboxCustomer(){
                const formdata = await {
                    action: "listcustomer",
                    id: "",
                }
                this.listCustomer = await [];
                const result = await this.$store.dispatch("params/getListSelectBox",formdata);
                if(result.success){
                    this.listCustomer = await result.data;
                }
            },
            async getListSelectCustomerFormData(){
                const formdata = await {
                    action: "selectcustomerbyid",
                    id: this.formCustomerData.form_customer,
                }
                this.formCustomerData.form_customer_name = await '';
                this.formCustomerData.form_customer_tel = await '';
                this.formCustomerData.form_customer_address = await '';
                const result = await this.$store.dispatch("params/getListSelectBox",formdata);
                if(result.success){
                    this.formCustomerData.form_customer_name = await result.data.cus_name;
                    this.formCustomerData.form_customer_tel = await result.data.cus_tel;
                    this.formCustomerData.form_customer_address = await result.data.cus_address;
                }
            },
            async getListSelectCustomerToData(){
                const formdata = await {
                    action: "selectcustomerbyid",
                    id: this.formCustomerData.to_customer,
                }
                this.formCustomerData.to_customer_name = await '';
                this.formCustomerData.to_customer_tel = await '';
                this.formCustomerData.to_customer_addredd = await '';
                const result = await this.$store.dispatch("params/getListSelectBox",formdata);
                if(result.success){
                    this.formCustomerData.to_customer_name = await result.data.cus_name;
                    this.formCustomerData.to_customer_tel = await result.data.cus_tel;
                    this.formCustomerData.to_customer_addredd = await result.data.cus_address;
                }
            },
            async goNextStep(){
                if(this.customerValid){
                    if(this.formCustomerData.form_customer_name == ''){
                        this.$refs.formcustomerdata.validate();
                        return false;
                    }else if(this.formCustomerData.form_customer_tel == ''){
                        this.$refs.formcustomerdata.validate();
                        return false;
                    }else if(this.formCustomerData.to_branch == ''){
                        this.$refs.formcustomerdata.validate();
                        return false;
                    }else if(this.formCustomerData.to_customer_name == ''){
                        this.$refs.formcustomerdata.validate();
                        return false;
                    }else if(this.formCustomerData.to_customer_tel == ''){
                        this.$refs.formcustomerdata.validate();
                        return false;
                    }else{
                        this.stepwizard = await 2;
                    }
                }else{
                    this.$refs.formcustomerdata.validate();
                    return false;
                }
            },
            async addMorePackage(){
                if(this.packageValid){
                    const obj = await {
                        rno: 0,
                        package_no : 0,
                        package_name : this.formPackageData.package_name,
                        package_amount : 1,
                        package_price : this.formPackageData.package_price,
                        package_description : '',
                        is_edit: false,
                    }
                    await this.listPackage.push(obj);
                    this.formPackageData = await {
                        package_name: '',
                        package_price: 0,
                    }
                    var total = await 0;
                    for (const [i, rows] of Object.entries(this.listPackage)) {
                        rows.rno = await Number.parseInt(i) + 1;
                        total += await Number.parseFloat(rows.package_price);
                    }
                    this.sumtotal = await total;
                }else{
                    this.$refs.formpackagedata.validate();
                    return false
                }
            },
            async getEditPackage(data){
                var index;
                for (const [i, rows] of Object.entries(this.listPackage)) {
                    if(rows.rno == data.rno){
                        index = await i
                    }
                }
                this.formEditPackageData = await {
                    package_name: data.package_name,
                    package_amount: data.package_amount,
                    package_price: data.package_price,
                    package_description: data.package_description,
                };
                this.packageindex = await index;
                this.formDialog = await true;
            },
            async deleteData(index){
                this.$swal.fire({
                    title: 'ລຶບຂໍ້ມູນ',
                    text: 'ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ຫລື ບໍ່ ?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#85ce61',
                    cancelButtonColor: '#F56C6C',
                    confirmButtonText: '<i class="mdi mdi-check-circle-outline"></i>  ຕົກລົງ',
                    cancelButtonText: '<i class="mdi mdi-close-circle-outline"></i>  ຍົກເລີກ'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        var isindex = 0;
                        for (const [i, rows] of Object.entries(this.listPackage)) {
                            if(rows.rno == data.rno){
                                isindex = i;
                            }
                        }
                        await this.listPackage.splice(isindex, 1);
                        var total = await 0;
                        for (const [i, rows] of Object.entries(this.listPackage)) {
                            rows.rno = await Number.parseInt(i) + 1;
                            total += await Number.parseFloat(rows.package_price);
                        }
                        this.sumtotal = await total;
                    }
                });
            },
            async saveEdit(){
                this.$swal.fire({
                    title: 'ແກ້ໄຂຂໍ້ມູນ',
                    text: 'ທ່ານຕ້ອງການບັນທຶກແກ້ໄຂຂໍ້ມູນ ຫລື ບໍ່ ?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#85ce61',
                    cancelButtonColor: '#F56C6C',
                    confirmButtonText: '<i class="mdi mdi-check-circle-outline"></i>  ຕົກລົງ',
                    cancelButtonText: '<i class="mdi mdi-close-circle-outline"></i>  ຍົກເລີກ'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.listPackage[this.packageindex].package_name = await this.formEditPackageData.package_name;
                        this.listPackage[this.packageindex].package_amount = await this.formEditPackageData.package_amount;
                        this.listPackage[this.packageindex].package_price = await this.formEditPackageData.package_price;
                        this.listPackage[this.packageindex].package_description = await this.formEditPackageData.package_description;
                        this.formDialog = await false;
                        var total = await 0;
                        for (const [i, rows] of Object.entries(this.listPackage)) {
                            rows.rno = await Number.parseInt(i) + 1;
                            total += await Number.parseFloat(rows.package_price);
                        }
                        this.sumtotal = await total;
                        this.$toast.fire("ແກ້ໄຂສຳເລັດ","","success");
                    }
                });
            },
            async saveData(){
                if(this.listPackage.length > 0){
                    this.$swal.fire({
                        title: 'ບັນທຶກຂໍ້ມູນ',
                        text: 'ທ່ານຕ້ອງການບັນທຶກຂໍ້ມູນ ຫລື ບໍ່ ?',
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#85ce61',
                        cancelButtonColor: '#F56C6C',
                        confirmButtonText: '<i class="mdi mdi-check-circle-outline"></i>  ຕົກລົງ',
                        cancelButtonText: '<i class="mdi mdi-close-circle-outline"></i>  ຍົກເລີກ'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            const formdata = await {
                                action: 'addpackage',
                                form_customer_name : this.formCustomerData.form_customer_name,
                                form_customer_tel : this.formCustomerData.form_customer_tel,
                                form_customer_address : this.formCustomerData.form_customer_address,
                                to_branch : this.formCustomerData.to_branch,
                                to_customer_name : this.formCustomerData.to_customer_name,
                                to_customer_tel : this.formCustomerData.to_customer_tel,
                                to_customer_address : this.formCustomerData.to_customer_addredd,
                                package: this.listPackage,
                            }
                            await this.$store.commit("auth/setLoading", true);
                            const result = await this.$store.dispatch("manage/getManagePackage", formdata);
                            await this.$store.commit("auth/setLoading", false);
                            if(result.success){
                                this.$toast.fire(result.message,"","success");
                                await this.$router.push({name: 'import'});
                                
                            }else{
                                this.$swal.fire("Oops",result.message,"warning");
                            }
                        }
                    });
                }else{
                    this.$swal.fire({
                        title: 'Oops',
                        text: 'ກະລຸນາປ້ອນຂໍ້ມູນພັດສະດຸກ່ອນ',
                        icon: 'warning',
                    })
                }
                
            }
        },
    }
</script>


<style scoped>
    .v-stepper .v-stepper--non-linear .v-sheet .theme--light{
        background: transparent !important;
    }
    .v-sheet.v-stepper:not(.v-sheet--outlined){
        box-shadow: none !important;
    }

    .v-stepper__header{
        height: 25px !important;
        box-shadow: none !important;
    }
    .v-stepper__step{
        padding: 10px 25px !important;
    }

    .row{
        margin: 0 !important;
    }

    .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1{
        padding: 5px !important;
    }
</style>